
import { ref, computed, onMounted, defineComponent, watch } from 'vue'
import {
  useBreadcrumbs,
  useStructures,
  useTenant,
  useLocalizations,
} from '@/core'
import { useHead } from '@vueuse/head'
import { StructuresNode } from 'types/interface/StructuresNode'
import { Concept } from 'types/interface/Concept'

import {
  getConceptsLanguage,
  setConceptsLanguage,
} from '@/services/languageSelect'
import { useI18n } from 'vue-i18n'
import useTranslations from '@/composables/useTranslations'
import useModal from '@/composables/useModal'
import TheHeading from '@/components/TheHeading.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import LanguageSelect from '@/components/LanguageSelect.vue'
import Card from '@/components/Card.vue'
import Searchbox from '@forlagshuset/v-searchbox'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const lunr = require('lunr')
// eslint-disable-next-line @typescript-eslint/no-var-requires
require('lunr-languages/lunr.stemmer.support')(lunr)
// eslint-disable-next-line @typescript-eslint/no-var-requires
require('lunr-languages/lunr.no')(lunr)

export default defineComponent({
  name: 'ConceptsSearch',

  components: {
    TheHeading,
    Breadcrumbs,
    LanguageSelect,
    Card,
    Searchbox,
  },

  setup() {
    const { setBreadcrumbs, clearBreadcrumbs } = useBreadcrumbs()
    const currentLanguage = ref('')
    const loading = ref(true)
    const lunrIdx = ref(null)
    const term = ref('')
    const { tenant } = useTenant()
    const { setModal } = useModal()
    const parentSlugPath = computed(() => tenant.value.concepts.slug)
    const {
      getStructuresBySlugPath,
      fetchStructuresChildren,
      getStructuresChildrens,
    } = useStructures()
    const { gqlStructureQuery, gqlStructureChildrenQuery, isFallbackLanguage } =
      useTranslations()
    const { fetchStructuresLocalization } = useLocalizations()
    const { locale } = useI18n()
    const parentStructure = computed(() =>
      getStructuresBySlugPath(parentSlugPath.value, true),
    )
    const languageList = computed(() => {
      return tenant.value.concepts.langs.map(
        (lang: {
          code: string
          label: {
            [key: string]: string
          }
        }) => {
          return {
            value: lang.code,
            label: lang.label[locale.value],
          }
        },
      )
    })
    const onLanguageChange = async (lang: string): Promise<void> => {
      await setConceptsLanguage(lang)
      currentLanguage.value = lang
    }
    const conceptsTitle = computed(
      () => (parentStructure.value && parentStructure.value.name) || '',
    )
    const concepts = computed(() => {
      return getStructuresChildrens(parentStructure.value.id, 'DIRECTORY', true)
    })
    const finalConceptsResults = computed(() => {
      let result

      if (!lunrIdx.value) return []
      result = lunrIdx.value
        .search('+' + term.value + '*')
        .map(
          (obj: Record<string, unknown>) =>
            concepts.value.filter(
              (concept: StructuresNode) => obj.ref === concept.id,
            )[0],
        )

      return result.sort(
        (
          a: {
            name: string
          },
          b: {
            name: string
          },
        ) => a.name.localeCompare(b.name, 'no'),
      )
    })
    const cardActions = {
      type: 'concept',
      action: (concept: StructuresNode) => {
        onShowConcept(concept)
      },
    }
    const onShowConcept = (concept: StructuresNode) => {
      setModal('ModalConcept', {
        title: concept.name,
        content: concept,
        type: 'concept',
        options: {
          translation: currentLanguage.value,
        },
      })
    }
    const setPhrase = (e: string) => {
      term.value = e
    }
    useHead({
      title: computed(() => `${conceptsTitle.value} - ${tenant.value.name}`),
    })

    const loadContent = async () => {
      await gqlStructureQuery(tenant.value.concepts.slug, locale.value, {})
      try {
        if (!isFallbackLanguage.value) {
          await fetchStructuresLocalization(
            parentStructure.value.id,
            locale.value,
          )
        }
      } catch {
        //
      }
      try {
        if (!isFallbackLanguage.value) {
          const limit = 200
          let hasItems = true
          let page = 0
          while (hasItems) {
            const res = await gqlStructureChildrenQuery(
              parentStructure.value.id,
              locale.value,
              { limit, page },
            )
            const { total } = res.data.structure.data.children.pagination
            page++
            if (total <= page * limit) {
              hasItems = false
            }
          }
        } else {
          await fetchStructuresChildren(
            tenant.value.concepts.slug,
            {
              limit: 1000,
              filter: `{"type": "DIRECTORY"}`,
            },
            {
              namespace: tenant.value.concepts.namespace,
            },
          )
        }
      } catch {
        //
      }

      currentLanguage.value = (await getConceptsLanguage()) || ''
      const searchableText = () =>
        concepts.value.map((concept: Concept) => {
          concept.searchableContent = concept.description

          return concept
        })

      lunrIdx.value = lunr(function () {
        this.use(lunr.no)
        this.pipeline.remove(lunr.no.stemmer)
        this.searchPipeline.remove(lunr.no.stemmer)
        this.pipeline.remove(lunr.no.stopWordFilter)
        this.searchPipeline.remove(lunr.no.stopWordFilter)
        this.ref('id')
        this.field('name', { boost: 100 })

        searchableText().forEach((st: string) => {
          this.add(st)
        })
      })
      loading.value = false
    }

    watch(locale, () => {
      loadContent()
    })

    onMounted(async () => {
      clearBreadcrumbs()
      loadContent()
      setBreadcrumbs([{ name: conceptsTitle.value }])
    })

    return {
      loading,
      conceptsTitle,
      parentSlugPath,
      finalConceptsResults,
      onShowConcept,
      cardActions,
      setPhrase,
      term,
      languageList,
      onLanguageChange,
      currentLanguage,
    }
  },
})
