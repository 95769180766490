import DB from '@/core/services/db'
import { useAuth, useTenant } from '@/core'

const db = new DB('edtech')
const { user } = useAuth()
const { tenant } = useTenant()
const conceptsLanguageId = `${tenant.value.namespace}-${user.value.providerId}-concepts-language`

const getConceptsLanguage = async (): Promise<string> => {
  try {
    const lang = await db.getData(conceptsLanguageId)

    return lang.value
  } catch {
    return ''
  }
}

const setConceptsLanguage = async (lang: string): Promise<void> => {
  try {
    await db.createOrUpdate({
      _id: conceptsLanguageId,
      name: 'conceptsLanguage',
      value: lang,
    })
  } catch {
    // silence
  }
}

export { getConceptsLanguage, setConceptsLanguage }
