import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "v-concepts__filtering" }
const _hoisted_2 = { class: "v-concepts__search" }
const _hoisted_3 = { class: "v-concepts__filter" }
const _hoisted_4 = { class: "v-concepts__wrapper" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TheHeading = _resolveComponent("TheHeading")!
  const _component_Breadcrumbs = _resolveComponent("Breadcrumbs")!
  const _component_Searchbox = _resolveComponent("Searchbox")!
  const _component_LanguageSelect = _resolveComponent("LanguageSelect")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["v-concepts", _ctx.parentSlugPath])
  }, [
    _createVNode(_component_TheHeading, {
      class: "v-concepts__title",
      level: "h1"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.conceptsTitle), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_Breadcrumbs),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_Searchbox, {
          class: _normalizeClass(["c-concepts-search__searchbox u-px--3", [{ 'c-concepts-search__searchbox--has-phrase': _ctx.term }]]),
          searchFormLabel: _ctx.$t('SEARCH_FORM'),
          searchPlaceholder: _ctx.$t('CONCEPTS_SEARCH_PLACEHOLDER'),
          searchButton: _ctx.$t('SEARCH'),
          characters: 2,
          onSubmitSearch: _ctx.setPhrase
        }, null, 8, ["class", "searchFormLabel", "searchPlaceholder", "searchButton", "onSubmitSearch"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_LanguageSelect, {
          languageList: _ctx.languageList,
          currentLanguage: _ctx.currentLanguage,
          canClear: true,
          canDeselect: true,
          placeholder: _ctx.$t('CONCEPTS_LANG_PLACEHOLDER'),
          label: _ctx.$t('CONCEPTS_LANG_LABEL'),
          onLanguageChange: _ctx.onLanguageChange
        }, null, 8, ["languageList", "currentLanguage", "placeholder", "label", "onLanguageChange"])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.finalConceptsResults, (concept) => {
        return (_openBlock(), _createBlock(_component_Card, {
          class: "v-concepts__concept",
          key: concept.id,
          title: concept.name,
          description: concept.description,
          "image-id": concept.cover,
          item: concept,
          actions: _ctx.cardActions,
          "has-button": "",
          "button-text": _ctx.$t('CONCEPTS_CARD_BUTTON_TEXT')
        }, null, 8, ["title", "description", "image-id", "item", "actions", "button-text"]))
      }), 128))
    ]),
    (!_ctx.loading && !_ctx.finalConceptsResults.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_component_TheHeading, { class: "v-concepts__no-results" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('CONCEPTS_SEARCH_NO_RESULTS')), 1)
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode(_component_TheHeading, { class: "v-concepts__loading" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('CONCEPTS_SEARCH_LOADING')) + "... ", 1)
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ], 2))
}